(function($) {
    'use strict';
    window.ingelogd = {
        init: function() {
	        console.log('ingelogd.js initialized');
            this.bindings();
        },
        bindings() {
            var context = this,
                btnMeldingen = $('.btn-meldingen'),
                btnMeldingenInner = $('.btn-meldingen .inner'),
                dropdownMeldingen =  $('.meldingen-dropdown'),
                btnProfile = $('.btn-profile'),
                btnProfileInner = $('.btn-profile .inner'),
                dropdownProfile = $('.profile-dropdown');

            btnMeldingen.on('click', 'a.inner', function(e) {
                e.preventDefault();
                toggleDropdown(dropdownMeldingen, $(e.currentTarget));
            });

            btnMeldingen.on('keypress', 'a.inner', function(e) {
                e.preventDefault();
                if (e.which == 13) {
                    toggleDropdown(dropdownMeldingen, $(e.currentTarget));
                }
            });

            btnProfile.on('click', 'a#btn-profile' , function(e) {
                e.preventDefault();
                toggleDropdown(dropdownProfile, $(this));
            });

            btnProfile.on('keypress', function(e) {
                toggleDropdown(dropdownProfile, $(this));
            });

            $(document).on('keyup', function(e) {
                if (e.keyCode == 27) {
                    closeDropdown(dropdownMeldingen, btnMeldingenInner);
                    closeDropdown(dropdownProfile, btnProfileInner);
                }
            });

            $(document).on('click', function(e) {
                if ($('.meldingen-dropdown').hasClass('open') && $(e.target).closest(".meldingen-dropdown .inner-dropdown").length == 0) {
                    closeDropdown(dropdownMeldingen, btnMeldingenInner);
                }
                if ($('.profile-dropdown').hasClass('open') && $(e.target).closest(".profile-dropdown .drop-down").length == 0) {
                    closeDropdown(dropdownProfile, btnProfileInner);
                }
            });

            function toggleDropdown(dropdownElement, $this) {
                if (dropdownElement.hasClass('closed')) {
                    dropdownElement.removeClass('closed');
                    setTimeout(function() {
                        dropdownElement.addClass('open');
                        $this.attr('aria-expanded', 'true');
                    }, 5);
                } else {
                    closeDropdown(dropdownElement, $this);
                }

                if (dropdownElement == dropdownMeldingen) {
                    closeDropdown(dropdownProfile, btnProfileInner);
                } else {
                    console.log('else');
                    closeDropdown(dropdownMeldingen, btnMeldingenInner);
                }
            }

            function closeDropdown(dropdownElement, $this) {
                dropdownElement.removeClass('open').addClass('close');
                setTimeout(function() {
                    dropdownElement.addClass('closed');
                    dropdownElement.removeClass('close');
                    $this.attr('aria-expanded', 'false')
                }, 400);
            }
        },
    };
}(jQuery));

$(document).ready(function() {
    let $asyncBtn = $('.btn-meldingen._async_load');
    if ($asyncBtn.length == 0 || $(".meldingen-dropdown", $asyncBtn).length == 1) {
        ingelogd.init();
    } else {
        $asyncBtn.on('async-update async-load-failed', function(){
            ingelogd.init();
        });
    };		
});
